<template>
	<div
	v-if="core !== false"
	:style="{ '--bg-statut-color': color}"
	coring-core-card
	class="tw-p-[5px]"
	>
		<div class="first-part">
			<div class="tw-flex tw-flex-col tw-overflow-hidden tw-h-full tw-justify-between">
				<div class="tw-flex tw-flex-row">
					<h4>
						Carotte #{{ core.displayName ? core.displayName : core.number }} {{ core.displayName ? '('+core.number+')' : '' }}
					</h4>

					

					<div class="tooltip tw-flex tw-flex-col tw-overflow-hidden tw-h-full tw-justify-between">
						<div class="text-ellipsis tw-flex tw-flex-row tw-items-center">
							<h4
							v-if="core.displayNamePrefix"
							class="tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]"
							>
								&nbsp;{{ '- '+core.displayNamePrefix.name + core.displayName }}
							</h4>

							<h4
							v-else
							class="tw-whitespace-nowrap tw-text-ellipsis tw-overflow-x-hidden lineHeight tw-mr-[5px]"
							>
								&nbsp;{{ '- '+core.businessId }}
							</h4>
						</div>
						<div class="tooltiptext">
							{{ core.displayNamePrefix ? core.displayNamePrefix.name + core.displayName : core.businessId }}
						</div>
					</div>
				</div>

				<div
				v-if="core.extractedAt"
				style="font-size: 10px;"
				>
					Prélevée le {{ moment(core.extractedAt).format("DD/MM/Y") }}
				</div>
			</div>
			
			<span :title="infoStatusCore.label">
				<!-- <v-icon>{{infoStatusCore.icon}}</v-icon> -->
				<label :style="{'color': textColor}">
					{{ label }}
				</label>
			</span>


			<ButtonSlot
			v-if="infoStatusCore.label === 'Extraite'"
			_icon="mdi-check-outline"
			_full-rounded
			@click="setSendToManagerCore()"
			:_small="true"
			_theme="green"
			_tooltip="Valider l'extraction"
			/>
		</div>

		<div class="second-part">
			<ButtonSlot
			@click="map.jumpTo({center: [core.lng, core.lat]})"
			_icon="mdi-crosshairs-gps"
			_icon-size="22px"
			_theme="none"
			_full-rounded
			_small
			/>

			<ButtonSlot
			@click="removeCore"
			v-if="campaign.status === 'awaiting_validation' && !isEditDisabled && $hasRight('campaigns.editCampaignBtn')"
			:_popup="{
				title: 'Supprimer la carotte?',
				subTitle: 'Êtes-vous sûr de vouloir effectuer cette action ?\nElle sera irréversible.',
			}"
			_icon="mdi-delete"
			_icon-size="22px"
			_theme="none"
			_full-rounded
			_small
			_auto-loading
			/>

			<ButtonSlot
			v-if="($hasRight('users.isCustomer') && core.status === 'waiting') || $hasRight('campaigns.editCampaignBtn')"
			@click="START_EDITING_CORE(core)"
			:_disabled="isEditDisabled"
			:_icon="
				(
					core.status === 'sent_to_study_manager' || 
					core.status === 'empty' ||
					core.status === 'waiting' ||
					core.status === 'validated'
				) &&
					
					campaignStatus.step < 4 && !$hasRight('users.isCustomer')
					?
						'mdi-pencil' :
						'mdi-eye'
			"
			_icon-size="22px"
			_theme="none"
			_full-rounded
			_small
			/>

			<ButtonSlot
			v-if="!$hasRight('campaigns.editCampaignBtn') && core.status === 'waiting'"
			@click="validateCustomerCore(false)"
			_icon="mdi-close-thick"
			_icon-size="22px"
			_color="red"
			_theme="none"
			_full-rounded
			_small
			_tooltip="Invalider les informations d'implantation de la carotte"
			/>

			<ButtonSlot
			v-if="!$hasRight('campaigns.editCampaignBtn') && core.status === 'waiting'"
			@click="validateCustomerCore(true)"
			_icon="mdi-check-bold"
			_icon-size="22px"
			_color="green"
			_theme="none"
			_full-rounded
			_small
			_tooltip="Valider les informations d'implantation de la carotte"
			/>
		</div>
	</div>
</template>

<script>
import coring from "../../utils/coring";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
	name: "CoringCard",
	components: {
        
	},
	props: {
		core: {
			default: false
		}
	},
	data(){
		return {
			infoStatusCore: false,
		};
	},
	computed: {
		...mapGetters("coring", [
			"map", "campaign", "campaignStatus"
		]),

		label(){
			if(this.campaign.status === "awaiting_validation" && this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === null) return "En attente";
			else if(this.campaign.status === "awaiting_validation" && this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === true) return "Validée";
			else if(this.campaign.status === "awaiting_validation" && this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === false) return "Invalidée";
			else if(this.campaign.status === "awaiting_validation" && this.core.status === "waiting") return "A prélever";
			return this.infoStatusCore.label;
		},

		color(){
			if(this.campaign.status === "awaiting_validation"){
				if(this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === null) return "grey";
				if(this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === true) return "green";
				if(this.campaign.shouldValidateCustomer && this.core.isValidatedCustomer === false) return "red";
				if(this.core.status === "waiting") return "green";
				if(this.core.status === "validated") return "green";
				if(this.core.status === "empty") return "orange";
				if(this.core.status === "cancelled") return "red";
			}
			else if(this.campaign.status === "programing_validated"){
				if(this.core.status === "waiting") return "orange";
				if(this.core.status === "validated") return "green";
				if(this.core.status === "sent_to_study_manager") return "green";
				if(this.core.status === "cancelled") return "red";
			}
			else if(this.campaign.status === "taken"){
				if(this.core.status === "validated") return "green";
				if(this.core.status === "sent_to_study_manager") return "orange";
				if(this.core.status === "cancelled") return "red";
			}
			else if(this.campaign.status === "taking_validated"){
				if(this.core.status === "results_obtained") return "green";
				if(this.core.status === "validated") return "orange";
				if(this.core.status === "sent_for_analysis") return "green";
				if(this.core.status === "cancelled") return "red";
			}
			return "#D8D8D8";
		},
		textColor(){
			let textColor;
			this.color === "#D8D8D8" ? textColor = "black" : textColor = "white";
			return textColor;
		},
		isEditDisabled(){
			if(this.campaign.status === "taken" && this.core.status !== "sent_to_study_manager" && this.core.status !== "validated"){
				return true;
			}
			if(this.campaign.status === "awaiting_validation" && this.core.status !== "empty" && this.core.status !== "waiting"){
				return true;
			}
			return false;
		}
	},
	watch: {
		core(){
			if(this.core !== false){
				this.init();
			}
		}
	},
	methods: {
		...mapMutations("coring", ["START_EDITING_CORE"]),
		...mapActions("coring", ["deleteCore", "forceUpdateCores"]),

		removeCore(){
			this.deleteCore(this.core).then(e => {
				this.forceUpdateCores(this.campaign.id);
			});
		},

		init(){
			this.infoStatusCore = coring.getInfoStatusCore(this.core);
		},
		validateCustomerCore(isValidated){
			this.$api.cores.patch({id: this.core.id, validateCustomer: isValidated}).then(e => {
				this.forceUpdateCores(this.campaign.id);
			});
		},
		setSendToManagerCore(){
			this.$api.cores.patch({id: this.core.id, status: "sent_to_study_manager"}).then(e => {
				this.forceUpdateCores(this.campaign.id);
			});
		}

	},
	created(){
		this.init();
	}
    
};
</script>

<style lang="scss">
div[coring-core-card]{
    overflow: hidden;
    user-select: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: solid rgba(0,0,0,0.12) 1px;
    display: flex;
    gap: 5px;
    align-items: center;

    > .first-part{
        display: flex;
        gap: 5px;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;

        > span {
            background: var(--bg-statut-color);
            color: white;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 2px 7px;
            border-radius: 999px;
            font-size: 12px;
            overflow: hidden;
            min-width: 12px;

            > .v-icon {
                color: white;
                font-size: 12px;
            }

            > label{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-shrink: 1;

                &::before{
                    position: fixed;
                    content: attr(tooltip);
                }
            }

        }
    }

    > .second-part{
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
    }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -5px; /* Ajustez la distance par rapport au texte coupé */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
