<!--
	Tous composants et toutes vues sont séparés en 3 parties.
	- La partie HTML, qui commence toujours par une balise <template></template>, et dans laquelle il est important de n'avoir qu'un seul élément enfant à sa racine
	- La partie JavaScript (ou TypeScript dépendant du projet), introduite par la balise <script></script> dans laquelle on détermine nos variables utilisables dans 
	  la partie HTML, nos méthodes, computed, et où l'ont peut intégrer les stores
	- La partie CSS, introduite par la balise <style></style> que l'ont peut scope à la vue ou au composant (de façon a ce que le CSS n'ait pas d'impact non voulu ailleurs)
	  et qui peut être utilisé en SCSS, donc avec des variables dynamiques
-->
<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<!-- 
			Appel du composant CsvImportPopin, avec des conditions particulières
			Le v-if permet d'avoir un affichage en fonction d'une condition, comme un if classique. Le composant sera intégré que dans le cas où la condition sera valide
			Le v-show lui permet de gérer la propriété display du CSS, donc il est différent du v-if, car le composant est intégré quoi qu'il arrive, il n'est cependant visible
			qu'en cas de condition valide.
			Concernant le @closePopin, c'est une action qui s'effectue quand le composant enfant émet au parent l'évènement closePopin de la manière suivante : 
			this.$emit('closePopin');
			Cela permet une communication de l'enfant vers le parent
		-->
		<CsvImportPopin
		v-if="campaign.status === 'awaiting_validation'"
		v-show="csvImportPopInDisplay"
		@closePopin="csvImportPopInDisplay = false"
		/>

		<!--
			Même principe ici que pour le composant précédent, avec deux évènements écoutés, yesButton et noButton
			Le nombre d'évènement écouté n'as pas de limite sur un composant
		-->

		<AskPrestationsPopin 
		v-if="askPrestations === true"
		@yesButton="validateStructuralCampaign"
		@noButton="validateStructuralCampaign"
		/>

		<!--
			Même principe de nouveau que pour les deux composants précédents, avec ici en plus des paramètres passés à l'enfant :
			Site, campaign et needButton
			Ces 3 éléments sont récupérables dans l'enfant sous form de "props", et utilisable au même titre qu'une variable déclarée dans le "data" de la partie script
			A la seule différence que l'enfant ne peux pas en modifier la valeur de façon direct en lui attribuant une valeur direct.
			Une façon de le faire serait avec un emit, et une fonction qui y passerait la valeur.
			A savoir qu'un this.$emit() peut prendre un second paramètre qui est une valeur que le parent peut utiliser
			De même, les deux points utilisés devant le nom des paramètres permet de passer une variable plutot que du texte
		-->
		<SendCoringCampaignToProviderValidationPopin
		v-if="campaign.status === 'taking_validated'"
		:site="campaign.workspace.site"
		v-show="isCoringCampaignSendDataPopinDisplay"
		@closePopin="isCoringCampaignSendDataPopinDisplay = false"
		:campaign="campaign"
		:needButton="true"
		/>

		<!--
			Ici nous avons le HeaderSlot, qui est un composant présent sur toutes les vues (ou presque) et permettant l'affichage du titre, d'un sous titre, du lien
			du bouton de retour, et de la possibilité de cacher le bouton de retour
		-->

		<HeaderSlot
		:toBackButton="{ name: 'project', params: { id: campaign.workspace.id } }"
		:title="campaign.name || ''"
		:subTitle="campaignStatus.label || ''"
		>
			<!--
				Ici le ButtonSlot, très utilisé pour créer des boutons, tous identiques, prennant plusieurs paramètres, comme l'évènement @click, les thèmes pour les couleurs,
				les icones, la possibilité de passer en icone uniquement... 
			-->
			<ButtonSlot
			@click="getTemporaryPDF()"
			v-if="campaignStatus.step > 2"
			_theme="light-gray"
			_icon="mdi-file"
			:_res="1150"
			>
				Rapport provisoire
			</ButtonSlot>

			<ButtonSlot
			@click="refreshData()"
			v-if="campaign.status === 'sent_for_analysis' && !campaign.asbestosResultCount"
			:_res="1150"
			_icon="mdi-refresh"
			>
				Rafraîchir les données
			</ButtonSlot>

			<ButtonSlot
			v-if="campaignStatus.step >= 5 && campaign.asbestosResultCount"
			:_to="{
				name: 'coring-campaign-dashboard',
				params: { id: $route.params.id }
			}"
			:_res="1150"
			_icon="mdi-file-document-outline"
			>
				Accéder aux données
			</ButtonSlot>

			<ButtonSlot
			@click="setCampaignStatus('programing_validated')"
			:_disabled="!isCoresValid || (campaign.shouldValidateCustomer && !campaign.isValidatedCustomer)"
			v-if="campaign.status === 'awaiting_validation' && $hasRight('campaigns.configureCoringAndStart')"
			_icon="mdi-check-outline"
			:_tooltip="!isCoresValid ? 'Certaines carottes ne sont pas validées, impossible de valider la campagne.' : ''"
			:_res="1150"
			:_popup="{
				title: 'Valider le placement des carottes ?',
				subTitle: 'Voulez-vous soumettre votre campagne à votre technicien de carottage ?\nAprès envoi, cette campagne ne sera plus modifiable.'
			}"
			_auto-loading
			>
				valider la programmation
			</ButtonSlot>

			<ButtonSlot
			@click="validateCampaign"
			:_disabled="!isCoresValid"
			v-if="campaign.status === 'taken' && $hasRight('campaigns.configureCoringAndStart')"
			_icon="mdi-check-outline"
			:_tooltip="!isCoresValid ? 'Certaines carottes ne sont pas validées, impossible de valider la campagne.' : ''"
			:_res="1150"
			:_popup="{
				title: 'Valider le relevé des carottes ?',
				subTitle: 'Si vous confirmez cette action vous ne pouvez plus revenir en arrière.'
			}"
			_auto-loading
			>
				valider le relevé des carottes
			</ButtonSlot>

			<ButtonSlot
			@click="setCampaignCoresSentToStudyManager()"
			v-if="campaign.status === 'programing_validated' && $hasRight('campaigns.configureCoringAndStart')"
			_icon="mdi-check-outline"
			:_res="1150"
			_auto-loading
			:_disabled="cores.find(core => core.status === 'extracted') === undefined"
			>
				Valider les carottes extraites
			</ButtonSlot>

			<ButtonSlot
			@click="setCampaignTaken()"
			v-if="campaign.status === 'programing_validated' && $hasRight('campaigns.configureCoringAndStart')"
			_icon="mdi-check-outline"
			:_res="1150"
			_auto-loading
			:_disabled="cores.find(core => core.status === 'sent_to_study_manager' || core.status === 'validated') === undefined"
			>
				Valider le prélèvement
			</ButtonSlot>

			<ButtonSlot
			@click="redirectToPrestationSettings()"
			v-if="campaign.status === 'taking_validated' && $hasRight('campaigns.configureCoringAndStart')"
			_icon="mdi-pencil-outline"
			:_res="1150"
			>
				Paramétrer les prestations
			</ButtonSlot>
		</HeaderSlot>

		<div class="tw-grow tw-overflow-hidden tw-flex tw-flex-col lg:tw-flex-row tw-gap-[10px]">
			<!--
				Ici nous avons le composant qui gère l'affichage de la map
				Ce composant des classes. Ces classes, qui commencent principalement par "tw-" sont des classes tailwind, autogénérées à l'execution de la commande
				détaillée dans la documentation Front / Back
			-->
			<CoringMap 
			ref="coringMap"
			class="tw-h-[calc(50%-10px)] tw-w-full lg:tw-w-[calc(70%-10px)] lg:tw-h-full"
			/>

			<div class="tw-h-[50%] tw-w-full tw-flex tw-flex-col lg:tw-w-[calc(30%)] lg:tw-overflow-hidden lg:tw-h-full lg:tw-min-w-[340px] tw-gap-[10px]">
				<div 
				v-if="!editingCore && campaign.status === 'awaiting_validation'" 
				class="tw-flex tw-full tw-justify-center tw-gap-[5px]"
				>
					<ButtonSlot
					v-if="campaign.status === 'awaiting_validation' && $hasRight('campaigns.createCampaignBtn')"
					@click="isAddingCore === false? START_ADDING_CORE() : STOP_ADDING_CORE()"
					:class="{ 'tw-opacity-[0.5]': isAddingCore }"
					_icon="mdi-map-marker-plus-outline"
					>
						{{ !isAddingCore ? "Ajouter une carotte" : "annuler l'ajout de carotte" }}
					</ButtonSlot>

					<ButtonSlot
					v-if="campaign.status === 'awaiting_validation' && $hasRight('campaigns.createCampaignBtn')"
					@click="csvImportPopInDisplay = true"
					_icon="mdi-file"
					_theme="light-gray"
					>
						Importation CSV
					</ButtonSlot>

					<ButtonSlot
					v-if="campaign.status === 'awaiting_validation' && $hasRight('users.isCustomer')"
					@click="validateCampaignCustomer()"
					:_disabled="!allCoresValidated() || campaign.isValidatedCustomer"
					_icon="mdi-check-bold"
					_theme="light-gray"
					>
						Valider la préparation
					</ButtonSlot>
				</div>

				<div
				v-if="!editingCore"
				class="tw-grow tw-overflow-hidden tw-overflow-y-auto tw-flex tw-flex-col tw-gap-[5px] tw-pr-[5px] tw-items-center"
				>
					<div
					class="tw-flex tw-flex-row tw-gap-[10px]"
					v-if="$hasRight('campaigns.createCampaignBtn') && campaign.status === 'awaiting_validation' && !campaign.isValidatedCustomer"
					>
						<label
						dir="rtl"
						for="switch"
						class="tw-text-[rgba(0,0,0,.6)] tw-select-none tw-h-[40px] tw-align-middle tw-leading-[40px] tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis"
						>
							Demander une validation client
						</label>
						<v-switch
						v-model="campaign.shouldValidateCustomer"
						@change="updateShouldValidateCustomer()"
						class="tw-items-center methodology"
						hide-details
						id="switch"
						/>
					</div>

					<!--
						Ici nous avons un autre composant, le CoringCoreCard, qui correspond à la liste des carottes sur le côté droit de la page.
						On y retrouve un v-for qui prend en paramètre un tableau, et qui permet d'appeler X fois le composant, et d'y passer un ou plusieurs paramètre,
						ici le "core"
						Avec l'utilisation d'un v-for, il faut toujours une clé "key" de façon à éviter les erreurs dans la console.
						Cette clé permet à Vuetify d'identifier les éléments 
					-->
					<CoringCoreCard
					v-for="core of cores"
					:key="core.number"
					:core="core"
					class="tw-shrink-0"
					/>
				</div>

				<CoringCoreForm
				v-if="editingCore"
				@displayMethodology="displayDirMethodology = true"
				@cancelDisplayMethodology="displayDirMethodology = false"
				@displayMaterial="displayMaterials = true"
				@cancelDisplayMaterial="displayMaterials = false"
				@displayStructuralType="displayStructuralType = true"
				@cancelStructuralType="displayStructuralType = false"
				class="tw-h-full tw-w-full"
				/>

				<dirMethodologyHelpVue
				class="tw-absolute tw-z-10"
				v-if="displayDirMethodology"
				@close="displayDirMethodology = false"
				/>

				<!--<materialsHelpVue
				class="tw-absolute tw-z-10"
				v-if="displayMaterials"
				@close="displayMaterials = false"
				/>-->

				<structureTypeHelp
				class="tw-absolute tw-z-10"
				v-if="displayStructuralType"
				@close="displayStructuralType = false"
				/>
			</div>
		</div>

		<SiteDeactivated v-if="isSiteDeactivated"/>
	</div>
</template>

<!-- Ici nous retrouvons le début de la partie JavaScript -->
<script>
/* Ici nous avons les différents imports, aussi bien les composants utilisés dans la partie HTML, que les modules comme vuex, pour pouvoir utiliser le store dans cette vue */
import CoringMap from "../../components/coring/Coring.Map.vue";
import CoringCoreCard from "../../components/coring/Coring.Core.Card.vue";
import CoringCoreForm from "../../components/coring/Coring.Core.Form";
import CsvImportPopin from "../../components/coring/CsvImport.popin.vue";
import SendCoringCampaignToProviderValidationPopin from "@/components/providers/sendCoringCampaignToProviderValidation.popin.vue";
import {mapGetters, mapActions, mapMutations} from "vuex";
import SiteDeactivated from "../../components/campaign/SiteDeactivated.vue";
import AskPrestationsPopin from "../../components/coring/prepare/AskPrestations.popin.vue";
import dirMethodologyHelpVue from "../../components/coring/dirMethodologyHelp.vue";
//import materialsHelpVue from "../../components/coring/materialsHelp.vue";
import structureTypeHelp from "../../components/coring/structureTypeHelp.vue";
/* Ici on retrouve le vrai départ de la partie JavaScript, après import, avec l'exportation du contenu JS, fonctionnement classique de VueJS */
export default {
	// Ici le nom du composant, qui techniquement n'a pas d'intérêt précis dans notre cas, mais permet de vite retrouver le fichier en cas de recherche via l'IDE
	name: "CoringCampaign",
	// Ici tous les composants utilisés dans la partie front, et importés juste au dessus, que l'on doit déclarer dans cet objet, de façon a pouvoir les utiliser. A ne pas oublier !
	components: {
		CoringMap,
		CoringCoreCard,
		CoringCoreForm,
		CsvImportPopin,
		SendCoringCampaignToProviderValidationPopin,
		SiteDeactivated,
		AskPrestationsPopin,
		dirMethodologyHelpVue,
		structureTypeHelp
	},
	// Ici la partie Data qui retourne toutes les variables qui seront utilisable dans le scope du composant, aussi bien en JS qu'en HTML (entre accolades pour la partie HTML)
	data(){
		return {
			csvImportPopInDisplay: false,
			kmlImportPopInDisplay: false,
			isCoringCampaignSendDataPopinDisplay: false,
			hasPrestations: false,
			isSiteDeactivated: false,
			askPrestations: false,
			displayPdfParams: false,
			displayDirMethodology: false,
			displayMaterials: false,
			displayStructuralType: false
		};
	},
	/* 
		Ici les computed, qui peuvent être utilisés comme des fonctions, mais qui sont executées avant les méthodes. Elles permettent globalement de faire de l'affichage
	 	dynamique facilement, en évitant toute condition complexe dans la partie HTML, avec des données préchargée à l'affichage du composant (ne fonctionne pas en asynchrone)
		On y retrouve aussi le mapping des getters, qui correspond aux valeurs récupérées depuis le store, ici les stores "coring" et "downloader".
		L'utilisation de ces variables se fait aussi par l'intermédiaire de "this"
	 */
	computed: {
		...mapGetters("coring", [
			"campaign", "cores", "editingCore", "isCoresValid", "campaignStatus", "isAddingCore"
		]),
		...mapGetters("downloader", ["downloading", "downloadList"])
	},
	/*
		La partie watch est utilisée pour trigger une action au changement de valeur d'une variable précise. C'est utile dans des cas bien spécifiques, notamment sur l'ouverture
		ou la fermeture d'une popup par exemple
	*/
	watch: {
		isAddingCore(){
			if(this.isAddingCore !== false){
				window.onkeydown = (e) => {
					if(e.key === "Escape") this.STOP_ADDING_CORE();
				};
			}
			else {
				window.onkeydown = () => {};
			}
		}
	},
	/*
		La partie methods regroupe toutes les fonctions utilisées dans la vue ou le composant. Elle permettent aussi bien de traiter des données, que de faire
		des appels API.
		Les methods peuvent être asynchrone, principalement pour attendre les réponses d'appels API. Pour cela, il faut ajouter "async" devant le nom de la fonction
		On y retrouve aussi l'appel des mutations et des actions du store. Leur fonctionnement est le même que pour les getters, il faut les appeler avec le this
	*/
	methods: {
		// Les mutations sont un moyen d'assigner des valeurs à un state du store. Elles sont utilisées quand la valeur est déjà connue et ne nécessite pas d'appel API
		...mapMutations("coring", [
			"START_ADDING_CORE", "STOP_ADDING_CORE", "STOP_EDITING_CORE"
		]),
		/* 
			Les actions sont un peu comme des fonctions, directement enregistrées dans le store, permettant aussi de mettre à jour des states, mais le plus souvent
			via un appel API. Souvent on les utilises pour initier les states du store et pour y faire des mises à jours d'objet suite à un envoie de formulaire par exemple
		*/
		...mapActions("coring", [
			"initStore", "purgeStore", "setCampaignStatus", "updateCores", "setCampaign", "forceUpdateCores", "setCampaign"
		]),
		...mapActions("downloader", [
			"setDownloading", "deleteFromDownloadList", "setDownloadList", "setDownloadStatus"
		]),

		// Ici nous avons une fonction de redirection qui utiliser le router pour push une nouvelle page, que l'on retrouve dans l'index du router, avec un name et un paramètre
		redirectToPrestationSettings(){
			this.$router.push({
				name: "coring-campaign-prestations",
				params: {id: this.$route.params.id}
			});
		},
		/*
			Ici nous avons une autre fonction qui utiliser un appel API avec this.api, mais qui n'est pas asynchrone. C'est une autre façon de faire les choses, 
			avec un .then dont le contenu s'execute une fois la réponse de l'appel récupéré.
		*/
		getTemporaryPDF(){
			let fileName = "LivrablesMaorie" + this.campaign.name.replaceAll(" ", "_") + "_Temporaire.pdf";
			this.setDownloading(true);
			this.setDownloadList([...this.downloadList, {name: fileName, downloadState: "loading"}]);
			this.$api.coringCampaigns.getTemporaryPDF(
				this.$route.params.id
			).then(response => {
				var newBlob = new Blob([response], {type: "application/pdf"});
				const data = window.URL.createObjectURL(newBlob);
				var link = document.createElement("a");
				link.href = data;
				link.download = this.campaign.name;
				window.open(link, "_blank");
				this.setDownloadStatus({downloadState: "finished", element: fileName});
			}).catch(error => {
				console.log(error);
				this.setDownloadStatus({downloadState: "failed", element: fileName});
			});
			
		},
		// Ici une fonction asynchrone, avec un "await" devant les appels API de façon à attendre le retour des données pour continuer l'execution du code
		async refreshData(){
			await this.$api.coringCampaigns.getFromProvider(this.$route.params.id);
			await this.setCampaign(this.$route.params.id);
		},

		async validateCampaign(){
			if(this.campaign.coringType === "CS"){
				this.askPrestations = true;
				/*this.STOP_EDITING_CORE();
				await this.setCampaignStatus("results_obtained");
				this.$router.push({
					name: "structural-coring-campaign-dashboard",
					params: {id: this.$route.params.id}
				});*/
			}
			else {
				await this.setCampaignStatus("taking_validated");
			}
		},

		async setCampaignTaken(){
			await this.setCampaignStatus("taken");
		},

		setCampaignCoresSentToStudyManager(){
			this.$api.cores.validateCoresByCampaign(this.$route.params.id).then(e => {
				this.forceUpdateCores(this.campaign.id);
			});
		},

		async validateStructuralCampaign(isPrestation){
			if(isPrestation === false){
				this.STOP_EDITING_CORE();
				await this.setCampaignStatus("results_obtained");
				this.$router.push({
					name: "coring-campaign-dashboard",
					params: {id: this.$route.params.id}
				});
			}
			else {
				await this.setCampaignStatus("taking_validated");
			}
			this.askPrestations = false;
		},

		async hasCampaignPrestations(){
			const prestations = await this.$api.campaigns.getPrestations(
				this.$route.params.id
			);
			this.hasPrestations = prestations.length != 0;
		},
		async validateCampaignCustomer(){
			await this.$api.coringCampaigns.updateValidateCustomer(this.campaign.id, true);
			await this.setCampaign(this.$route.params.id);
		},
		allCoresValidated(){
			if(this.cores !== false){
				return this.cores.every(core => core.isValidatedCustomer !== null);
			}
			return true;
		},
		async updateShouldValidateCustomer(){
			await this.$api.coringCampaigns.updateShouldValidateCustomer(this.campaign.id, this.campaign.shouldValidateCustomer);
		}
	},
	// Ici nous avons la partie "mounted", qui est executée au lancement de la page. Elle fait partie du LifeCycle de VueJS disponible ici : https://vuejs.org/api/options-lifecycle.html

	async mounted(){
		let response = await this.initStore({id: this.$route.params.id});
		
		if(response === "site_deactivated"){
			this.isSiteDeactivated = true;
			return;
		}

		this.hasCampaignPrestations();
	},
	// De même que le mounted, le destroyed s'execute lui au moment où l'on coupe la page, et fait partie du LifeCycle de VueJS aussi
	destroyed(){
		this.purgeStore();
	}
};
</script>

<!-- 
	Ici nous retrouvons enfin le CSS, qui n'est pas scoped dans ce cas là, mais que l'ont pourrait ajouter de la manière suivante : 
	<style lang="scss" scoped>
-->

<style lang="scss">
.methodology {
    position: relative;
    bottom: 12px;
  }

  .methodology ::v-deep .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
</style>
